<template>
  <div style="background-color: #f4f5f7; padding-bottom: 50px; margin-top: -52px; ">
    <div class="gigContainer">
      <div>

        <div class="owner-area">
          <div class="list-name">
            <p class="list-title">{{title}}</p>
            <p class="list-user">{{owner.username}} tarafından oluşturuldu.</p>
          </div>
        </div>


        <div  class="top-menu">
          <div class="menu-option-wrapper">
            <div style="display: flex; align-items: center">
              <div class="menu-option-item" :class="activeState === 'gig' ? 'active' : null" @click="activeState = 'gig'" style="margin-left: 15px" :style="activeState === 'open' ? 'border: none; width: 140px' : null">
                Servisler ({{ counts.gig }})
              </div>
              <div class="menu-option-item" :class="activeState === 'freelancer' ? 'active' : null" @click="activeState = 'freelancer'" :style="activeState === 4 ? 'border: none; width: 140px' : null">
                <span>Freelancerlar ({{counts.freelancer}})</span>
              </div>
            </div>

          </div>
        </div>


        <div class="gig-list-container">
          <div v-if="!pageLoaded" style="display: flex;flex-wrap: wrap; margin-top: 1px">
            <div v-for="x in 3" class="card-container">
              <div class="card-image-container card">
              </div>
              <div class="card-detail">
                <div class="card-detail-container">
                  <div class="card-detail-header-large card">

                  </div>
                  <div class="card-detail-header-small card">

                  </div>
                </div>
                <div class="card-detail-user-container ">
                  <div class="card-detail-user">
                    <div class="card-detail-user-image card"></div>
                    <div class="card-detail-user-name card"></div>
                  </div>
                  <div class="card-detail-currency card">
                  </div>
                </div>
                <div>
                </div>
              </div>
              <div class="card-detail-seperator card">

              </div>
              <div class="card-like-currency">
                <div class="card-like-icon card"></div>
                <div class="card-currency-icon card"></div>
              </div>
            </div>
          </div>
          <template v-if="activeState === 'gig'">
            <single-gig :impression-u-u-i-d="impressionUUID" :item="fav"  v-for="(fav, findex) in gigs" class="gig-list-gig"  :key="fav.uuid"/>



            <div v-if="pageLoaded && (!gigs || gigs.length === 0)" class="ee">
              <img style="width: 200px; margin-top: 30px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
              <p class="ee-title">Bu listede hiç iş ilanı yok.</p>
              <p class="ee-text">
                Listeye iş ilanı eklemek için ilgini çeken bir iş ilanı sayfasına git ve o sayfa üzerinden bu listeye o ilanı ekle.
              </p>
              <custom-button button-type="green" style="margin-top: 30px; margin-bottom: 30px" @click="$router.push('/bugun')">İlanları Keşfet</custom-button>

            </div>

          </template>
          <template v-if="activeState === 'freelancer'">
            <div class="cardBoxContainer" >
              <div class="cardBoxFlexC" v-for="(freelancer, fiindex) in freelancers" :key="freelancer.username">
                <div class="cardBox">
                  <div
                    :data-balloon="freelancer.online_status ? 'Çevrimiçi' : freelancer.last_seen | lastSeen() + ' önce görüldü.'"
                    data-balloon-pos="left"
                    class="cardOnline" :style="freelancer.online_status ? ' background-color: #5beb17;': ' background-color: #bfc8d2;'"></div>
                  <div class="rainDropcontainer">
                    <div class="firstLayer"></div>
                    <div class="secondLayer"></div>
                    <div class="thirdLayer"></div>
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'favorites')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,'favorites')"
                      :to="'/'+freelancer.username">
                      <img :src="freelancer.avatar" class="freelancerAvatar">
                    </router-link>
                  </div>
                  <p class="username">{{freelancer.username}}</p>
                  <p class="title">{{freelancer.title}}</p>
                  <div class="rating">
                    <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                    <div v-if="freelancer.rating && freelancer.rating.commentCount">
                      <span class="rate">{{freelancer.rating.commentRating === 5 ? '5.0' : freelancer.rating.commentRating }}</span>
                      <span class="count"> ({{freelancer.rating.commentCount}})</span>
                    </div>
                  </div>

                  <div class="reText">
                    <div @click="$router.push('/'+freelancer.username)" class="followers-count">
                      <div class="inner-text"> <span style="font-weight: 600; color: #2d3640;">Profile Git</span></div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <div v-if="pageLoaded && (!freelancers || freelancers.length === 0)" class="ee">
              <img style="width: 200px; margin-top: 30px;" src="https://gcdn.bionluk.com/uploads/message/b7de1367-db4c-4667-adcb-7ee6ef7487ce.png" />
              <p class="ee-title">Bu listede hiç freelancer ilanı yok.</p>
              <p class="ee-text">
                Listeye freelancer eklemek için ilgini çeken bir freelancer'ın profil sayfasına git ve o sayfadan üzerinden bu listeye o freelancer'ı ekle.
              </p>
              <custom-button button-type="green" style="margin-top: 30px; margin-bottom: 30px" @click="$router.push('/bugun')">Freelancerları Keşfet</custom-button>

            </div>
          </template>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

import CustomButton from "@/pages/components/CustomButton";
import singleGig from "@/pages/components/singleGig";
export default {
  name: "src-pages-body-marketplace-liste-v1",
  components:{singleGig, CustomButton},
  data() {
    return {
      id:null,
      pageLoaded: false,
      title:'',
      owner:{},
      counts:{},
      gigs:[],
      freelancers:[],
      activeState:'gig',
      impressionUUID:''
    }
  },

  methods: {
    getList(){

      this.api.general.getPublicList(this.id, this.$Progress)
        .then(({data}) => {
          let result = data;
          if(result.success){
            this.pageLoaded = true;
            this.title = result.data.title;
            this.counts = result.data.counts;
            this.gigs = result.data.gigs;
            this.owner = result.data.user;
            this.freelancers = result.data.freelancers;
            this.$store.state.indexHTMLTitle = result.data.meta.title;
            this.$store.state.indexHTMLMetaDescription = result.data.meta.description;
            if(result.data.counts.gig === 0 && result.data.counts.freelancer > 0){
              this.activeState = 'freelancer';
            }

            this.Helper.trackEvents.pageView({ title:result.data.title, sender_id: result.data.user.id});


          } else {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({description: result.message});
            this.$router.push('/panel/listelerim');
          }
        })
        .catch(err => {
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.$router.push('/panel/listelerim');
        })
    }

  },

  watch: {
  },

  created() {
    this.impressionUUID = this.Helper.generateUUID();
    let slug = this.$store.state.routerParams[0];
    if(slug){
      this.id = slug.split('-').pop();
      this.getList();
    } else {
      this.$toasted.global.errorToast({description: result.message});
      this.$router.push('/panel/listelerim');
    }
  }

}
</script>

<style scoped lang="scss">

.followers-count{
  padding-left: 30px;
  padding-right: 30px;
  height: 34px;
  border-radius: 2px;
  border: dashed 1px #bfc8d2;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;

  .inner-text {
    height: 18px;
    font-size: 14px;
    text-align: center;
    color: #5e6b79;
  }
  &:hover{
    border: solid 1px #2d3740;
  }
}


.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-to {
  animation: bounce-out .0s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}


.card-container {
  width: 278px;
  height: 298px;
  background-color: #ffffff;
  border: solid 1px #dfe4ec;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;

  &:nth-child(4n+1) {
    margin-left: 0;
  }
}

.card-image-container {
  height: 148px;
  background-color: #fafbfc;
  border-bottom: solid 1px #dfe4ec;
}

.card-detail-container {
  padding: 15px;
}

.card-detail-header-large {
  width: 234px;
  height: 16px;
  background-color: #e1e6ed;
  margin-bottom: 4px;
}

.card-detail-header-small {
  width: 117px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-image {
  width: 28px;
  height: 28px;
  background-color: #dfe4ec;
  border-radius: 50%;
  margin-right: 8px;
}

.card-detail-user-name {
  width: 50px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-currency {
  width: 63px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-detail-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2px 15px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dfe4ec;
}

.card-detail-user {
  display: flex;
  align-items: center;
}

.card-like-icon {
  width: 16px;
  height: 16px;
  background-color: #e1e6ed;
}

.card-currency-icon {
  width: 42px;
  height: 20px;
  background-color: #bdc5d0;
}

.card-like-currency {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 15px;
}

.card {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}


.cardBoxContainer{

  transition: transform 0.5s ease 0s;
  visibility: visible;
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y:hidden;
  .cardBoxFlexC{
    display: flex;
    margin-right: 24px;
    &:last-of-type {
      //margin-right: 0;
    }
  }


  .reText{


    text-align: center;
    margin-top: 23px;
    height: 14px;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;

    display: flex;
    span{
      font-weight: bold;
    }
    p{
      line-height: 1;
      margin-left: 5px;
    }
    .reTextIcon /deep/ {
      width: 14px;
      height: 14px;
      path {
        fill: #8b95a1;
      }
    }
  }

  .cardBox{

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 200px;
    height: 311px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .cardOnline{
      position: absolute;
      margin-left: 170px;
      margin-top:15px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .profileButton{
      background-color: white;
      width: 140px;
      height: 34px;
      padding-bottom: 2px;
      border-radius: 2px;
      border: solid 1px rgba(45, 54, 64, 0.2);

      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #2d3640;
      transition: .2s;
      &:hover{
        background-color: #2d3640!important;
        color:#fff
      }

    }
    .username{
      margin-top: 10px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #2d3640;
    }

    .title{
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 16px;
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.8;
      letter-spacing: -0.5px;
      text-align: center;
      color: #8b95a1;
    }


    .rating{

      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: center;

      .rate{
        margin-left: 2px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffbe00;
      }

      .count{
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }

  }

  .pBox{
    width: 277px;
    height: 274px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    position: relative;
    display: flex;


    flex-direction: column;
    align-items: center;
    margin-left: 28px;
    margin-bottom: 30px;

    .header{

      display: flex;
      align-items: center;
      height: 67px;
      width: 100%;

    }

    .media{
      width: 277px; height: 158px;
      cursor: pointer;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .avatar{
      margin-left: 11px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username-p{

      font-size: 11px;
      font-weight: 300;
      color: #5e6b79;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 14px;
      max-width: 200px;

      a{
        font-size: 11px;
        font-weight: 600;
        color: #fa4a5e;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    .title{
      font-weight: 600;
      color: #2d3640;
      font-size: 14px;
    }


  }

  .playIcon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -130%);
    height: 42px;
    width: 42px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
    img {
      height: 42px;
      width: 42px;
    }
  }

}



.freelancerAvatar {
  width: 91px;
  height: 91px;
  border-radius: 100%;
  border: 2px solid #eaeaea;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 4;
}

.rainDropcontainer {
  position: relative;
  display: block;
  width: 132px;
  height: 132px;
  margin-top: 15px;
}


.firstLayer {
  width: 108px;
  height: 108px;
  border-radius: 100%;
  opacity: 0.1;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 3;
}

.secondLayer {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  opacity: 0.08;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
}

.thirdLayer {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  opacity: 0.05;
  background-image: linear-gradient(225deg, #7fadf4, #6181f7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 1;
}


.ee{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ee-title{
    margin-top: 40px;
    font-size: 22px;
    font-weight: 600;
    color: #2d3640;
  }
  .ee-text{
    width: 300px;
    margin-top: 15px;
    font-size: 14px;
    font-weight: 300;

    line-height: 1.66;
    text-align: center;

    color: #5e6b79;
  }
}

.gig-list-container {

  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;


  .gig-list-gig{
    margin-bottom: 40px;

    &:nth-child(4n+1) {
     // margin-left: 0;
    }
  }

  &:hover {
    .swiper-button-prev, .swiper-button-next {
      visibility: visible;
    }
  }
}

.gigContainer {

  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;

}

.gig-main-div {
  display: inline-block;
  margin-bottom: 40px;
  margin-left: 30px;
  width: 263px;
  height: 296px;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
  vertical-align: top;
}

.top-menu{
  width: 90%;
  height: 70px;
  border-radius: 5px;

  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 10px;


  box-shadow: 0 0 6px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  align-items: center;

  display: flex;
  justify-content: space-between;
  .menu-option-wrapper{

    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-option-item{
      width: 139px;
      height: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      font-size: 14px;
      color: #5e6b79;
      line-height: 17px;
      cursor: pointer;
      border-radius: 0.5px;

      &:nth-child(4) {
        border: none;
        width: 140px;
      }
      &.active{
        width: 140px;
        height: 38px;
        line-height: 37px;
        border-radius: 2px;
        background-color: #fd4056;
        border: none;
        color: #ffffff;
      }
    }
  }
  .menu-search-wrapper{
    height: 60px;
    width: 183px;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: rgba(244, 245, 247, 0.2);
    margin: auto 20px auto 20px;
    cursor: pointer;
    .search-icon /deep/ {
      width: 22px;
      height: 22px;
      path{
        fill: #8b95a1;
      }
    }
  }

  .menu-search-active-wrapper{
    height: 60px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #eaedf2;
    background-color: rgba(244, 245, 247, 0.2);
    margin: auto 20px;
    display: flex;
    align-items: center;
    .delete-icon /deep/ {
      width: 22px;
      height: 22px;
      path{
        fill: #2d3640;
      }
    }
  }
}

.owner-area{
  margin-top: 40px;
  max-width: 100%;
  display: flex;
  .owner-avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border:1px solid #8B95A1;
  }

  .list-name{

    margin-left: 15px;
    max-width: 100%;

    .list-title{
      margin-top: 24px;
      font-size: 28px;
      font-weight: 500;
      color: #3c4654;
      max-width: 100%;
      text-overflow: ellipsis;


      white-space: pre-line;
    }

    .list-user{
      margin-top: 6px;
      font-size: 14px;
      font-weight: 300;
      color: #8b95a1;
    }
  }
}






</style>
